import React, { useState, useEffect } from 'react';
import './App.css';

const AnimatedNumber = ({ value, unit }) => {
  const [key, setKey] = useState(0);

  useEffect(() => {
    setKey(prevKey => prevKey + 1);
  }, [value]);

  return (
    <span className="animated-number" key={key}>
      {value}{unit}
    </span>
  );
};

const CountdownWebsite = () => {
  const [timeLeft, setTimeLeft] = useState(getTimeLeft());
  const [isExpired, setIsExpired] = useState(false);

  useEffect(() => {
    const timer = setInterval(() => {
      const newTimeLeft = getTimeLeft();
      setTimeLeft(newTimeLeft);
      setIsExpired(newTimeLeft.total <= 0);
    }, 1000);

    // Disable right-click
    const handleContextMenu = (e) => {
      e.preventDefault();
    };
    document.addEventListener('contextmenu', handleContextMenu);

    return () => {
      clearInterval(timer);
      document.removeEventListener('contextmenu', handleContextMenu);
    };
  }, []);

  function getTimeLeft() {
    const targetDate = new Date('2024-12-01T00:00:00').getTime();
    const now = new Date().getTime();
    const total = targetDate - now;

    const seconds = Math.max(Math.floor((total / 1000) % 60), 0);
    const minutes = Math.max(Math.floor((total / 1000 / 60) % 60), 0);
    const hours = Math.max(Math.floor((total / (1000 * 60 * 60)) % 24), 0);
    const days = Math.max(Math.floor(total / (1000 * 60 * 60 * 24)), 0);

    return { total, days, hours, minutes, seconds };
  }

  return (
    <div className="countdown-container">
      <h1>sam cheezit presents</h1>
      <div className="countdown">
        {isExpired ? (
          <span>Countdown Expired!</span>
        ) : (
          <>
            <AnimatedNumber value={timeLeft.days} unit="d" />
            <AnimatedNumber value={timeLeft.hours} unit="h" />
            <AnimatedNumber value={timeLeft.minutes} unit="m" />
            <AnimatedNumber value={timeLeft.seconds} unit="s" />
          </>
        )}
      </div>
    </div>
  );
};

function App() {
  return (
    <div className="App">
      <CountdownWebsite />
    </div>
  );
}

export default App;